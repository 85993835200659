import React from 'react';
import { FormGroup, Form } from 'react-bootstrap';

const Message = (props) => {
  return (
    <div>
      <div className="form-group">
        <label htmlFor="messageContents" className="col-sm-12 control-label">Message</label>
        <textarea
          className="form-control"
          rows="3"
          name="messageContents"
          placeholder="Enter Your Message"
          required
          value={props.value.messageContents}
          onChange={props.onChange} />
      </div>
      <div className="form-group">
        <div className="form-check">
          <label className="col-sm-12 control-label">Message Sender / Receiver</label>
          <FormGroup>
            <Form.Check
              type="radio"
              label="To"
              name="messageSenderReceiver"
              inline
              required
              value="to"
              onChange={props.onChange}
            />
            {' '}
            <Form.Check
              type="radio"
              label="From"
              name="messageSenderReceiver"
              inline
              required
              value="from"
              onChange={props.onChange}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default Message;