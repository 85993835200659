import React from 'react';

const MatchImageUploader = ({ onChange }) => {
  return (
    <div>
      <div className="form-group">
        <label htmlFor="pasteImage" className="col-sm-12 control-label">Add a Match Image</label>
        <div className="match-image-uploader">
          <input 
            type="text" 
            className="form-control" 
            id="pasteImage" 
            name="matchImageUploader" 
            placeholder="Paste URL Here" 
            onChange={onChange}
          />
          <input 
            type="file" 
            id="fileElem" 
            accept="image/*" 
            name="matchImageUploader" 
            style={{display:'none'}} 
            onChange={onChange}
          />
          <label htmlFor="fileElem" className="match-image-uploader_link">Click to Upload an Image Instead</label>
        </div>
        <div className="upload-image-preview"><img alt="Preview" /></div>
      </div>
    </div>
  );
};

export default MatchImageUploader;