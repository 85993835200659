import React, { useState, useEffect } from 'react';
import Home from './Home';
import DarkModeToggle from './DarkModeToggle';
import '../css/App.css';
import '../css/dark-mode.css';

const App = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, []);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', newDarkMode);
    document.body.classList.toggle('dark-mode', newDarkMode);
  };

  const renderFallingElements = () => {
    if (!darkMode) return null;
    return (
      <>
        <div className="falling-element fall-cat-sad fall-duration-1">😿</div>
        <div className="falling-element fall-cat-kiss fall-duration-2">😽</div>
        <div className="falling-element fall-phone fall-duration-3">📱</div>
        <div className="falling-element fall-message fall-duration-4">💬</div>
      </>
    );
  };

  return (
    <div className={`App ${darkMode ? 'dark' : ''}`}>
      <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      {renderFallingElements()}
      <Home />
    </div>
  );
};

export default App;