import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImageModal from './ImageModal';
import MatchImage from './MatchImage';
import MatchImageUploader from './MatchImageUploader';
import MatchName from './MatchName';
import Message from './Message';
import MessageTemplate from './MessageTemplate';
import StatusBar from './StatusBar';
import { profileImage, addMessage, phoneStatus } from '../actions';
import { Accordion, Card, Button } from 'react-bootstrap';
import girl_1 from '../images/pexels-photo-2.jpeg';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OSType: "",
      matchImage: girl_1,
      matchName: "",
      messageContents: "",
      messageSenderReceiver: "",
      messages: [],
      phoneBatteryPercent: "78",
      phoneServiceProvider: "AT&T",
      phoneTime: "7:43",
      AmPm: "PM"
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePhoneStatusChange = this.handlePhoneStatusChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.messageConstructor = {};
    this.phoneStatusConstructor = {};
  }

  handleChange(event) {
    const { name, value } = event.target;

    if (name === "matchImage") {
      this.setState({ matchImage: value });
      this.messageConstructor.matchImage = value;
      this.props.dispatch(profileImage(value));  // Ensure profile image is updated
    } else {
      this.setState({ [name]: value });
      this.messageConstructor[name] = value;
    }
  }

  handlePhoneStatusChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.phoneStatusConstructor[event.target.name] = event.target.value;
  }

  onFileChange(event) {
    const file = event.target.files ? event.target.files[0] : null;
    const isFileInput = file && file.type.startsWith('image/');

    const updateImage = (newImage) => {
      this.setState((prevState) => {
        const updatedMessages = prevState.messages.map(message => ({
          ...message,
          matchImage: newImage
        }));
        return {
          matchImage: newImage,
          messages: updatedMessages
        };
      });
      this.messageConstructor.matchImage = newImage;
      this.props.dispatch(profileImage(newImage));  // Ensure profile image is updated
    };

    if (isFileInput) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target.result;
        updateImage(imageDataUrl);
      };
      reader.readAsDataURL(file);
    } else {
      updateImage(event.target.value);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { matchImage, messageContents, messageSenderReceiver, phoneBatteryPercent, phoneServiceProvider, phoneTime, AmPm, messages } = this.state;

    const newMessage = { matchImage, messageContents, messageSenderReceiver };
    const newMsgArr = [...messages, newMessage];

    const updatedPhoneStatus = {
      phoneServiceProvider: this.phoneStatusConstructor.phoneServiceProvider || phoneServiceProvider,
      phoneTime: this.phoneStatusConstructor.phoneTime || phoneTime,
      AmPm: this.phoneStatusConstructor.AmPm || AmPm,
      phoneBatteryPercent: this.phoneStatusConstructor.phoneBatteryPercent || phoneBatteryPercent
    };

    this.setState({
      messageContents: "",
      messages: newMsgArr,
      ...updatedPhoneStatus
    });
    this.props.dispatch(addMessage(newMessage));
    this.props.dispatch(phoneStatus(updatedPhoneStatus));
    this.messageConstructor = {};
    this.phoneStatusConstructor = {};
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-4 no-gutters">
              <form className="message-form" onSubmit={this.handleSubmit}>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-white text-dark border-dark">
                      Create Message
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        <MatchName value={this.state} onChange={this.handleChange} />
                        <Message value={this.state} onChange={this.handleChange} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-white text-dark border-dark">
                      Add Image
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <MatchImageUploader onChange={this.onFileChange} /> {/* Fixed value */}
                        <MatchImage value={this.state} onChange={this.handleChange} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2" className="bg-white text-dark border-dark">
                      Change Phone Status
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <StatusBar value={this.state} onChange={this.handlePhoneStatusChange} />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Button type="submit" className="btn btn-outline-dark mt-3 text-white">
  Add Message
</Button>

              </form>
            </div>
            <div className="col-sm-12 col-md-8 no-gutters phone-and-img-gen-container">
              <div>
                <ImageModal {...this.props} />
              </div>
              <MessageTemplate value={this.state} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home = connect()(Home);

export default Home;