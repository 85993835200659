import React from 'react';
import girl_1 from '../images/pexels-photo-2.jpeg';
import girl_2 from '../images/girl-2.jpeg';
import guy_1 from '../images/guy-1.jpeg';
import guy_2 from '../images/guy-2.jpg';

const MatchImage = ({ value, onChange }) => {
  return (
    <div className="form-group">
      <label htmlFor="matchImageSelect" className="col-sm-12 control-label">Don't have an image? Use #Latergang Partner</label>
      <select 
        id="matchImageSelect"
        className="form-control"
        name="matchImage"
        value={value.matchImage}
        onChange={onChange}
      >
        <option value={girl_1}>undead cat</option>
        <option value={girl_2}>𝔟𝔬𝔟𝔟𝔶</option>
        <option value={guy_1}>Chris Mladek</option>
        <option value={guy_2}>Lunchmoney 69</option>
      </select>
    </div>
  )
}

export default MatchImage;