// components/SortableMessageComponent.js
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { moveMessage, deleteMessage, editMessage } from '../../actions';
import SortableMessageList from './SortableMessageList';

class SortableMessageComponent extends Component {
  constructor(props) {
    super(props);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.shouldCancelStart = this.shouldCancelStart.bind(this);
  }

  onSortEnd({ oldIndex, newIndex }) {
    if (oldIndex !== newIndex) {
      this.props.moveMessage(oldIndex, newIndex);
    }
  }

  shouldCancelStart(event) {
    return event.target.tagName === 'BUTTON' || event.target.tagName === 'INPUT';
  }

  render() {
    const { messages, profileImage, deleteMessage, editMessage } = this.props;

    return (
      <SortableMessageList
        messages={messages}
        onSortEnd={this.onSortEnd}
        shouldCancelStart={this.shouldCancelStart}
        onDelete={deleteMessage}
        profileImage={profileImage}
        editMessage={editMessage}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  messages: state.messages,
  profileImage: state.profileImage,
});

const mapDispatchToProps = {
  deleteMessage,
  moveMessage,
  editMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortableMessageComponent);