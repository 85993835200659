// components/SortableMessageItem.js
import React, { useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { connect } from 'react-redux';
import { editMessage } from '../../actions';

const SortableMessageItem = SortableElement((props) => {
  const { value, onDelete, currentProfileImage, editMessage } = props;
  const { id, messageContents, messageSenderReceiver } = value;
  
  const [isEditing, setIsEditing] = useState(false);
  const [newMessageContents, setNewMessageContents] = useState(messageContents);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setNewMessageContents(e.target.value);
  };

  const handleBlur = () => {
    if (newMessageContents !== messageContents) {
      editMessage(id, newMessageContents);
    }
    setIsEditing(false);
  };

  return (
    <li className={messageSenderReceiver === 'to' ? "message-list-item message-list-item_to" : "message-list-item"}>
      {messageSenderReceiver === 'from' && (
        <img src={currentProfileImage} className="img-circle message-list-item_img" alt="profile" />
      )}
      <div className={messageSenderReceiver === 'from' ? 'message-list-item-inner message-list-item-inner_from' : 'message-list-item-inner message-list-item-inner_to'}>
        {isEditing ? (
          <input
            type="text"
            value={newMessageContents}
            onChange={handleChange}
            onBlur={handleBlur}
            autoFocus
          />
        ) : (
          <span onClick={handleEdit}>{messageContents}</span>
        )}
      </div>
      <button onClick={() => onDelete(id)} className="btn btn-success message-delete-btn">DELETE</button>
    </li>
  );
});

const mapStateToProps = state => ({
  currentProfileImage: state.profileImage
});

const mapDispatchToProps = {
  editMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortableMessageItem);