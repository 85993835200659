import React, { useState, useEffect } from 'react';

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {
  return (
    <div className="dark-mode-toggle">
      <label className="switch">
        <input
          type="checkbox"
          checked={darkMode}
          onChange={toggleDarkMode}
        />
        <span className="slider round"></span>
      </label>
      <span className="mode-icon">{darkMode ? '🌙' : '☀️'}</span>
    </div>
  );
};

export default DarkModeToggle;