// actions/index.js

export const profileImage = (value) => ({
  type: 'SET_PROFILE_IMAGE',
  value
});

let nextMessageId = 0;
export const addMessage = (value) => ({
  type: 'ADD_MESSAGE',
  id: nextMessageId++,
  messageContents: value.messageContents,
  messageSenderReceiver: value.messageSenderReceiver,
});

export const deleteMessage = (id) => ({
  type: 'DELETE_MESSAGE',
  id,
});

export const editMessage = (id, messageContents) => ({
  type: 'EDIT_MESSAGE',
  id,
  messageContents,
});

export const moveMessage = (oldIndex, newIndex) => ({
  type: 'MOVE_MESSAGE',
  oldIndex,
  newIndex,
});

export const phoneStatus = (value) => ({
  type: 'SET_PHONE_STATUS',
  phoneBatteryPercent: value.phoneBatteryPercent,
  phoneServiceProvider: value.phoneServiceProvider,
  phoneTime: value.phoneTime,
  AmPm: value.AmPm,
});