// components/SortableMessageList.js
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableMessageItem from './SortableMessageItem';

const SortableMessageList = SortableContainer(({ messages, onDelete, editMessage }) => (
  <div className="sortable-list">
    {messages.map((value, index) => (
      <SortableMessageItem
        key={`item-${value.id}`} // Ensure key is unique
        index={index}
        value={value}
        onDelete={onDelete}
        editMessage={editMessage}
      />
    ))}
  </div>
));

export default SortableMessageList;