// Reducers
const message = (state, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        id: action.id,
        messageContents: action.messageContents,
        messageSenderReceiver: action.messageSenderReceiver,
        completed: false
      }
    default:
      return state
  }
}


// reducers/messages.js
// reducers/messages.js
import { arrayMove } from 'react-sortable-hoc';

const messages = (state = [], action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return [
        ...state,
        {
          id: action.id,
          messageContents: action.messageContents,
          messageSenderReceiver: action.messageSenderReceiver,
        },
      ];
    case 'DELETE_MESSAGE':
      return state.filter(message => message.id !== action.id);
    case 'EDIT_MESSAGE':
      return state.map(message =>
        message.id === action.id 
          ? { ...message, messageContents: action.messageContents } 
          : message
      );
    case 'MOVE_MESSAGE':
      return arrayMove(state, action.oldIndex, action.newIndex);
    default:
      return state;
  }
}

export default messages;