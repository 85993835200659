// reducers/index.js
import { combineReducers } from 'redux';
import profileImage from './profileImage';
import messages from './messages';
import phoneStatus from './phoneStatus';

const rootReducer = combineReducers({
  profileImage,
  messages,
  phoneStatus,
});

export default rootReducer;