import React, { Component } from 'react';
import html2canvas from 'html2canvas';

class ImageModal extends Component {
  constructor() {
    super();
    this.state = {
      imageData: null,
      isGenerating: false,
    };

    this.handleGenerateImage = this.handleGenerateImage.bind(this);
  }

  async handleGenerateImage() {
    this.setState({ isGenerating: true });
    await this.renderImage();
    this.setState({ isGenerating: false });
    this.handleDownload();
  }

  async renderImage() {
    try {
      const phoneContainer = document.querySelector('.phone-container');
      const deleteButtons = phoneContainer.querySelectorAll('.message-delete-btn');

      deleteButtons.forEach((button) => (button.style.display = 'none'));

      // Create a wrapper for the cloned container
      const clonedContainerWrapper = document.createElement('div');
      clonedContainerWrapper.style.position = 'absolute';
      clonedContainerWrapper.style.top = 0;
      clonedContainerWrapper.style.left = 0;
      clonedContainerWrapper.style.width = '100%';
      clonedContainerWrapper.style.height = '100%';
      clonedContainerWrapper.style.overflow = 'hidden';
      document.body.appendChild(clonedContainerWrapper);

      // Clone and append the container
      const clonedContainer = phoneContainer.cloneNode(true);
      clonedContainer.style.width = phoneContainer.offsetWidth + 'px';
      clonedContainer.style.height = phoneContainer.offsetHeight + 'px';
      clonedContainerWrapper.appendChild(clonedContainer);

      // Render the cloned container into canvas
      const canvas = await html2canvas(clonedContainerWrapper, {
        backgroundColor: null,
        scale: 2,
        logging: false,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
      });

      const imageData = canvas.toDataURL('image/png');
      this.setState({ imageData });

      deleteButtons.forEach((button) => (button.style.display = ''));
      document.body.removeChild(clonedContainerWrapper);
    } catch (error) {
      console.error('Error rendering image:', error);
    }
  }

  handleDownload() {
    if (this.state.imageData) {
      const link = document.createElement('a');
      link.download = 'iphone-meme.png';
      link.href = this.state.imageData;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  render() {
    return (
      <div>
        <button
          className="btn btn-danger mt-3"
          onClick={this.handleGenerateImage}
          disabled={this.state.isGenerating}
        >
          {this.state.isGenerating ? 'Generating Image...' : 'Generate Image 📷'}
        </button>
      </div>
    );
  }
}

export default ImageModal;