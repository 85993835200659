// reducers/phoneStatus.js
const initialState = {
  phoneBatteryPercent: "",
  phoneServiceProvider: "",
  phoneTime: "",
  AmPm: ""
};

const phoneStatus = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PHONE_STATUS':
      return {
        ...state,
        phoneBatteryPercent: action.phoneBatteryPercent,
        phoneServiceProvider: action.phoneServiceProvider,
        phoneTime: action.phoneTime,
        AmPm: action.AmPm
      };
    default:
      return state;
  }
}

export default phoneStatus;